.swatches {
  --ink: #696969;
  --ash: #a3a3a3;
  --sky: #9bbaed;
  --pop: #e43e23;
  --red: #d13b38;
  --jet: #000000;
  --gum: #eb5799;
  --hot: #ff0073;
  --mud: #4a342c;
  --fig: #F4E4FF;
  --tan: #d2b48c;
  --sea: #006699;
  --ruby: #e32040;
  --envy: #0e986b;
  --jade: #1b5c1d;
  --vert: #489c4a;
  --bone: #dedbbf;
  --aqua: #00ffff;
  --blue: #1d5caa;
  --cyan: #00ffff;
  --dirt: #a54400;
  --gold: #D4AF37;
  --gray: #808080;
  --grey: #808080;
  --lime: #00ff00;
  --navy: #273854;
  --nude: #E3BC9A;
  --rose: #e37fa0;
  --mint: #b6e4d0;
  --milk: #f6f2e8;
  --wine: #533230;
  --coal: #060d11;
  --snow: #f7f7f7;
  --dash: #234d90;
  --moss: #40592f;
  --peel: #ffd322;
  --sand: #e0c389;
  --onyx: #323737;
  --silk: #d1c4bb;
  --peru: #cd853f;
  --pink: #e79fab;
  --plum: #8a3340;
  --rust: #b7410e;
  --teal: #008080;
  --tile: #b9b6a1;
  --aloe: #D9EFD9;
  --azure: #f0ffff;
  --beige: #f5f5dc;
  --black: #000000;
  --brown: #804020;
  --cream: #ddd7cb;
  --terra: #b7a282;
  --slate: #4f4f4f;
  --olive: #40483f;
  --ocean: #E1E5F6;
  --camel: #a98d74;
  --clean: #F6F4EA;
  --coral: #ff7f50;
  --green: #008000;
  --ivory: #f5ecda;
  --khaki: #f0e68c;
  --linen: #faf0e6;
  --mauve: #e0b0ff;
  --wheat: #f5deb3;
  --white: #fafafa;
  --sienna: #9f6a51;
  --coffee: #2d2114;
  --bisque: #ffe4c4;
  --indigo: #4b0082;
  --maroon: #800000;
  --orange: #e5990e;
  --orchid: #da70d6;
  --purple: #800080;
  --tartan: #21a0ad;
  --salmon: #fa8072;
  --silver: #c0c0c0;
  --shadow: #5c534f;
  --tomato: #ff6347;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --natural: #b7ac9a;
  --saffron: #FFE4DF;
  --crimson: #dc143c;
  --neutral: #c6bda6;
  --fuchsia: #ff00ff;
  --magenta: #ff00ff;
  --thistle: #d8bfd8;
  --hot-pink: #ff69b4;
  --sky-blue: #87ceeb;
  --dark-red: #8b0000;
  --cornsilk: #fff8dc;
  --honeydew: #f0fff0;
  --metallic: #b2b0ab;
  --lavender: #e6e6fa;
  --moccasin: #ffe4b5;
  --midnight: #383863;
  --seashell: #fff5ee;
  --sea-green: #2e8b57;
  --rose-gold: #cf9fa6;
  --dark-blue: #00008b;
  --dark-cyan: #008b8b;
  --cool-grey: #b9c1c9;
  --dark-gray: #a9a9a9;
  --dark-grey: #a9a9a9;
  --deep-pink: #ff1493;
  --chocolate: #d2691e;
  --gainsboro: #dcdcdc;
  --steelblue: #4682b4;
  --turquoise: #40e0d0;
  --alabaster: #e8e7df;
  --dark-green: #006400;
  --dark-khaki: #bdb76b;
  --light-blue: #add8e6;
  --light-cyan: #e0ffff;
  --light-gray: #d3d3d3;
  --light-grey: #d3d3d3;
  --light-pink: #ffb6c1;
  --lime-green: #32cd32;
  --mint-cream: #f5fffa;
  --royal-blue: #4169e1;
  --slate-blue: #6a5acd;
  --slate-gray: #708090;
  --slate-grey: #708090;
  --aquamarine: #7fffd4;
  --chartreuse: #7fff00;
  --dark-orange: #ff8c00;
  --dark-salmon: #e9967a;
  --dark-violet: #9400d3;
  --light-coral: #f08080;
  --light-green: #90ee90;
}